import { render, staticRenderFns } from "./Result.vue?vue&type=template&id=6b182378&"
import script from "./Result.vue?vue&type=script&lang=js&"
export * from "./Result.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {UiPicture: require('/codebuild/output/src199449722/src/med-el-hearpeers/components/Ui/Picture.vue').default,Article: require('/codebuild/output/src199449722/src/med-el-hearpeers/components/Article/index.vue').default})
